<template>
  <v-row class="vfill" align="center" justify="center">
    <v-col cols="12">
      <Logo class="center" />
      <v-container class="small">
        <v-progress-linear
          height="1px"
          color="#fe9800"
          background-color="transparent"
          :value="value"
        >
        </v-progress-linear>
      </v-container>
      <transition name="fade" mode="out-in">
        <p v-if="futureIsHere" class="text-center margin-future">
          FUTURE IS HERE
        </p>
      </transition>
    </v-col>
  </v-row>
</template>

<script>
import Logo from "@/components/main/Logo.vue";

export default {
  name: "Home",
  components: {
    Logo,
  },

  data: () => ({
    value: 0,
    counter: 0,
    futureIsHere: false,
    timeout: 5000,
    futureTextTime: 1000,
    refreshRate: 25,
  }),

  mounted() {
    setTimeout(this.gotoHome, this.timeout + this.futureTextTime);
    this.progress = setInterval(this.updateProgress, this.refreshRate);
  },

  beforeDestroy() {
    clearInterval(this.progress);
  },

  methods: {
    gotoHome() {
      this.$router.push("/hello");
    },
    updateProgress() {
      this.value =
        (this.counter * 100) / ((this.refreshRate * this.timeout) / 1000.0);
      if (this.value >= 100) this.futureIsHere = true;
      this.counter++;
    },
  },
};
</script>

<style scoped>
.logo {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 10%;
  transform: translate(45%, 0px);
}

.main-title {
  position: absolute;
  top: 50%;
  width: 30%;
  transform: translate(30%, 0);
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
}
.vfill {
  height: 100%;
}
body {
  overflow: hidden;
}
.small {
  max-width: 15%;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  border-bottom: 3px solid #b2b4b7;
}
.margin-future {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
